.Piece {
  display: inline-block;
  z-index: 1;
  &__icon {
    font-size: 1em;
    &--white {
      color: var(--piece-light);
    }
    &--black {
      color: var(--piece-dark);
    }
  }
}

@media screen and (min-width: 360px) {
  .Piece__icon {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 540px) {
  .Piece__icon {
    font-size: 2em;
  }
}

@media screen and (min-width: 720px) {
  .Piece__icon {
    font-size: 2.5em;
  }
}

@keyframes move-piece {
  to {
    transform: translate(0, 0);
  }
}