.Tile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // Light tile color
  background-color: var(--tile-light);
  // Dark tile color
  @each $i in 2, 4, 6, 8, 9, 11, 13, 15 {
    &:nth-child(16n + #{$i}) {
      background-color: var(--tile-dark);
    }
  }
  // Cursor for interactive tiles
  &--interactive {
    cursor: pointer;
  }
  // Focus
  &:focus {
    outline: none;
    box-shadow: inset 0 0 3px 2px var(--indicator-color);
  }
  // Valid move indicator
  &__indicator {
    position: absolute;
    width: 60%;
    height: 60%;
    border-radius: 50%;
    background-color: var(--indicator-color);
  }
}