:root {
  --app-width: 100vw;
  --app-max-width: 800px;
  --app-padding: 1rem;

  --hue: 200;
  --text-color: #fff;
  --piece-dark: #000;
  --piece-light: #fff;
  --tile-dark: hsl(var(--hue), 50%, 50%);
  --tile-light: hsl(var(--hue), 50%, 65%);
  --indicator-color: hsl(var(--hue), 80%, 80%);
  --bg-color: hsl(var(--hue), 25%, 12%);
  --box-color: hsl(var(--hue), 25%, 30%);
  --subtle-color: hsl(var(--hue), 25%, 50%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--bg-color);
}