.TakenPieces {
  line-height: 2rem;
  min-height: calc(2rem + 1em + var(--app-padding));
  padding: calc(var(--app-padding) / 2);
  text-align: center;
  background-color: var(--subtle-color);
  &--white {
    grid-area: taken-pieces-white;
  }
  &--black {
    grid-area: taken-pieces-black;
  }
}