.App {
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-areas: 
    "board board" 
    "banner banner" 
    "taken-pieces-black taken-pieces-white" 
    "game-settings game-settings";
  gap: var(--app-padding);
  width: var(--app-width);
  max-width: var(--app-max-width);
  padding: var(--app-padding);
  margin: auto;
}